export const COLOURS: Colours = {
    MUNRO: {
        VISITED: "#C80537",
        UNVISITED: "#e799a9",
    },
    CORBETT: {
        VISITED: "#DE6A00",
        UNVISITED: "#eebe93",
    },
    GRAHAM: {
        VISITED: "#007859",
        UNVISITED: "#90d9c6",
    },
    MARILYN: {
        VISITED: "#5e0078",
        UNVISITED: "#ad90d9",
    },
    HUMP: {
        VISITED: "#444444",
        UNVISITED: "#AAAAAA",
    },
    SIMM: {
        VISITED: "#22a1b7",
        UNVISITED: "#a0bfd9",
    },
};

export type HillCategory = keyof Colours;

export type Colours = {
    MUNRO: HillColour;
    CORBETT: HillColour;
    GRAHAM: HillColour;
    MARILYN: HillColour;
    HUMP: HillColour;
    SIMM: HillColour;
};

type HillColour = {
    VISITED: string;
    UNVISITED: string;
};
